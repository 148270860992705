// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import SubTitle from "~components/typography/SubTitle";
import H1 from "~components/typography/H2";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import Blockquote from "~components/typography/Blockquote";
import TestmonialSingle from "~components/sections/TestmonialSingle";
import CtaBox from "~components/common/CtaBox";
import Alert from "~components/common/Alert";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageTestmonial: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Speaker"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero image={data.imageHeader} alt="Coaching mit Daniel Thiel">
				<SubTitle className="mb-2">Keynote-Speaker</SubTitle>
				<H1 display className="mb-4">
					Coaching zum Aufbau oder Ausbau Deines Geschäfts
				</H1>
				<P className="mb-4 ">
					Als Speaker nutzen Unternehmen meine Fähigkeiten der Kommunikation, Professionalität und den Charme des
					modernen Infotainment.
				</P>
				<P>Meine Themen:</P>
				<Ul
					items={[
						"New Work: Wer Leistung will, muss Sinn bieten",
						"Die richtigen Mitarbeiter finden und binden",
						"Balancieren von Mitarbeitern zwischen Leistungs- und Freizeitanspruch",
						"Generation Y, Zero, X, Z in der Führung",
					]}
					className="mb-4"
					pretty
				></Ul>

				<P className="mb-10">
					Buche einen Termin mit mir. Sehr gerne, unterhalten wir uns über Dein Anliegen, Ziele, Wünsche und
					Bedürfnisse,
				</P>
				{/* <Ul
					items={[
						"Löse Blockarden",
						"Schärfe Dein Mindset",
						"Vergangenheit und Zukunft",
						"Finde Dein Warum",
						"Erkenne Deine Stolpersteine",
						"Erkenne Deine Persönlichkei",
						"Erkenne Deine Stärken",
						"Entwickle Deine eigene Strategie",
					]}
					className="mb-10"
					pretty
				></Ul> */}
				<Button to="/speaker/anfragen/" text="Termin vereinbaren" />
			</Hero>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/speaker/speaker_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
